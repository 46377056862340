.logo-container {
    z-index: 0;
    width: 600px;
    height: 915px;
    position: absolute;
    top: 135%;
    right: 2%;
    bottom: 0;
    left: auto;
    margin: auto;
    //opacity: 0;

    svg {
        width:  100%;
        height: auto;
        bottom: 0;
        transform: rotateZ(-0deg) !important; // It is not reccomended to use !important but in this case we use for don't overload
    }

    .solid-logo1,
    .solid-logo2 {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        opacity: 0;
        transform: rotateZ(-0deg);
        z-index: 1;
    }

    .solid-logo1 {
        animation-delay: 4s;
        animation-duration: 3s;
        animation-name: slidein;
        animation-iteration-count: 1;
        animation-direction: alternate;
        animation-fill-mode: forwards;
        
    }

    .solid-logo2 {
        animation-delay: 7s;
        animation-duration: 3s;
        animation-name: swing;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
}

.svg-container {
    stroke: #00993F;
    stroke-width: 10px;
}

@keyframes slidein {
    from {
        opacity: 0;
        margin-left: 100%;
        width: 300%;
      }
    
      to {
        opacity: 1;
        margin-left: 0%;
        width: 100%;
      }
}

@keyframes swing  {
	0% { opacity: 0; transform: rotate(0deg); opacity: 1;}
    25% { transform: rotate(.5deg); opacity: 1;}
    50% { transform: rotate(-1deg); opacity: 1;}	
	100% { transform: rotate(0deg); opacity: 1;}
}

