.home-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40;
        max-height: 90%;
    }

    h1 {
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #00993F;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after {
            content: '<h1/>';
            font-family: 'La Belle Aurore';
            color: #00993F;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            left: 365px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }

        img {
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;          
        }

        .i {
            width: 30px;
            margin-left: 20px;
            opacity: 0;
            height: auto;               
        }

        .e {
            width: 35px;
            margin-left: 20px;
            opacity: 0;
            height: auto;               
        }

        .v {
            width: 44px;
            margin-left: 20px;
            opacity: 0;
            height: auto;               
        }
        
    }

    h2 {
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #599DFB;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #599DFB;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
        
            &::after{
                content: '!!';
            }

            background: #599DFB;
            color: #FFF;
            font-size: 13.5px;
            font-weight: bold;
        }
    }
}

@media screen and (max-width: 1200px) {
    .tags,
    .home-page h1::before,
    .home-page h1::after {
        display: none;
    }

    .home-page {
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
        }

        .flat-button {
            float: none;
            display: block;
            margin: 20px auto 0 auto;
            width: 125px;
        }

        .logo-container {
            position: relative;
            width: 100px;
            height: auto;
            top: 50px;
            right: 0;
            box-sizing: border-box;
            margin: auto;
            left: 0;

            svg {                       // For the svg animation in case to have one
                position: absolute;
                top: auto;
                right: auto;
                bottom: auto;
                left: 0;
                margin: auto;
            }
        }
    }
}