.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    // Enhanced image container with updated colors
    .image-box {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);
        border: 2px solid #D4C19C;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        box-shadow: 0 0 10px rgba(212, 193, 156, 0.3);
        
        &:hover {
            transform: scale(1.05);
            box-shadow: 0 0 20px rgba(212, 193, 156, 0.5);
        }

        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            transition: all 0.3s ease;

            &.portrait {
                object-fit: cover;
                transform: scale(1.1);
            }

            &.landscape {
                object-fit: cover;
            }
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 20px;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.8) 20%,
                rgba(0, 0, 0, 0.95) 100%
            );
            bottom: 0;
            transform: translateY(0);
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        .title {
            margin: 0 0 10px -20px;
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            line-height: 1.2;
            text-align: center;
    white-space: normal; // Prevents text from being squished in one line
    word-spacing: 2px; // Adjust spacing between words
        }

        .description {
            font-size: 16px;
            margin: 0 0 15px 0;
            color: #fff;
            font-weight: 400;
            line-height: 1.5;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-align: justify;
        }

        .btn {
            display: inline-block;
            padding: 10px 25px;
            border: 2px solid #21B868;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            font-weight: 600;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
            
            &:hover {
                transform: translateY(-3px);
                background: #21B868;
                box-shadow: 0 5px 15px rgba(33, 184, 104, 0.3);
            }
        }

        &:after {
            content: '';
            background: linear-gradient(180deg, #3182DF, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            transform: translateY(-10px);
        }

        &:hover .portfolio-image {
            transform: scale(1.2);
            
            &.portrait {
                transform: scale(1.3);
            }
        }
    }

    .loginDashboard {
        position: absolute;
        right: 35px;
        bottom: 0px;
        width: 0.25%;
        overflow: hidden;
    }
}

@media screen and (max-width: 1200px) {
    .container.portfolio-page {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        h1.page-title {
            margin-left: 20px;
            margin-top: 20px;
        }

        .image-box {
            height: 300px;
            max-width: calc(50% - 10px);
            
            .title {
                font-size: 20px;
            }
            
            .description {
                font-size: 14px;
                -webkit-line-clamp: 2;
            }
        }
    }
}