* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.rating-body {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // font-family: Arial, Helvetica, sans-serif;
    // background: linear-gradient(45deg, black,red,yellow);
}

h1 {
    color: #fff;
    animation: scale-up 1s ease;
}

@keyframes scale-up {
    0% {
        opacity: 0;
        transform: scale(.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.star_rating {
    user-select: none;
    font-weight: bolder;
    font-size: 16px;
    background-color: #fff;
    padding: 1.4rem 2.5rem;
    margin: 2rem;
    border-radius: .4rem;
    text-align: center;
    animation: slide-up 2s ease;
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}


.star {
    font-size: 4.5rem;
    color: #ff9800;
    background-color: unset;
    border: none;

    &:hover {
        cursor: pointer;
    }
}

