.boldText {
    color: #599DFB;
    font-weight: bold;
    text-decoration: none;
}

.links {
    color: #00993F;
    font-weight: bold;
    text-decoration: none;

    &:hover {
        color: #599DFB;
        text-transform: uppercase;

        &::after {
            content: '!!';
    }
    }
}

.cloud-cont {
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
}

// Cloud
@keyframes blinker {
    50% { opacity: 0.0; }
  }

  @media screen and (max-width: 1200px) {
    .cloud-cont {
        position: initial;
        width: 100%;
        height: auto;
        overflow: visible;
    }
    
  }